import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/Paper";
import FileUploadedDialogue from "./FileUploadedDialogue";
import Amplify, { API, Storage, DataStore } from "aws-amplify";
import { Moment, FolderType, FileType } from "../models";
import { EventSeatTwoTone } from "@mui/icons-material";
import awsconfig from "../aws-exports";
import "./AddForm.css";
Amplify.configure(awsconfig);

export function DBList(props) {
	const [data, setData] = useState([]);
	async function loadData() {
		const models = await DataStore.query(Moment);
		setData(models);
		console.log("found models");
		console.log(models);
	}
	useEffect(() => {
		loadData();
	}, []);
	return <div>sdfsafd</div>;
}

export function FilesList(props) {
	const { files } = props;
	const numberOfFiles = files.length;
	console.log(files);
	return (
		<div>
			Number of files: {numberOfFiles} <br />
			{files.map((file, index) => {
				return (
					<span key={index}>
						{file.name}
						{<img src={file} style={{ width: 400 }} />}
						<br />
					</span>
				);
			})}
		</div>
	);
}

export default function FileUploadPage() {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [numberOfFiles, setNumberOfFiles] = useState(0);
	const [files, setFiles] = useState([]);
	const [file, setFile] = useState();
	const [openDialogue, setOpenDialogue] = useState(false);
	const [caption, setCaption] = useState("");
	const [title, setTitle] = useState("");

	async function sendToS3(e) {
		if (!e.target.files[0]) return;
		const file = e.target.files[0];
		await Storage.put(file.name, file);
		console.log("filename: ", file.name);
	}

	async function sendFileToS3(f) {
		await Storage.put(file.name, file);
		setOpenDialogue(true);
		console.log("Sent file to S3: ", file.name);
	}

	const handleCloseDialogue = (value) => {
		console.log(value);
		setOpenDialogue(false);
	};

	const titleChangeHandler = (event) => {
		setTitle(event.target.value);
	};
	const textChangeHandler = (event) => {
		setCaption(event.target.value);
	};
	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
		setNumberOfFiles(numberOfFiles + 1);
		const copyFiles = [...files, event.target.files[0]];
		setFiles(copyFiles);

		setFile(event.target.files[0]);
		sendToS3(event);
	};
	async function getMoments() {
		const ret = await DataStore.query(Moment);
		console.log("ret");
		console.log(ret);
		return ret;
	}

	async function sendMetadata(data) {
		if (!data.Time) data.Time = "1970-01-01T12:30:23.999Z";
		if (!data.ZipS3Key) data.ZipS3Key = title;
		if (!data.FolderType) data.FolderType = FolderType.ZIP;
		if (!data.FileType) data.FileType = FileType.HEIC;
		if (!data.UserId) data.UserId = "dev";
		if (!data.Caption) data.Caption = caption;
		if (!data.Title) data.Title = title;
		if (!data.Progress) data.Progress = 0;

		console.log("trying to save to datastore");
		console.log(data);
		try {
			const m = new Moment(data);
			console.log(m);
			await DataStore.save(
				new Moment({
					UserId: "asfasfa " + Date.now(),
				})
			);
			console.log("successfully saved");
		} catch (error) {
			console.log("error saving");
			console.log(error);
		}
		console.log(data);
		await getMoments();
		console.log("auo");
	}

	const handleSubmission = () => {
		sendFileToS3(file);
		const metadata = {
			ZipS3Key: title,
		};
		sendMetadata(metadata);
	};

	return (
		<div className="widget">
			<Paper id="calendarChart">
				<DBList></DBList>
				{/*<FilesList files={files}></FilesList>*/}
				<span className="left">Upload file:</span> <br />
				<span className="left">Title:</span>
				<input type="text" onChange={titleChangeHandler}></input>
				<br />
				<span className="left">Caption:</span>
				<input type="text" onChange={textChangeHandler}></input>
				<br />
				<input type="file" name="file" onChange={changeHandler} />
				{/*isFilePicked ? (
					<div>
						<p>Filename: {selectedFile.name}</p>
						<p>Filetype: {selectedFile.type}</p>
						<p>Size in bytes: {selectedFile.size}</p>
						<p>
							lastModifiedDate:{" "}
							{selectedFile.lastModifiedDate.toLocaleDateString()}
						</p>
					</div>
				) : (
					<p>Select a file to show details</p>
				)*/}
				<div>
					<button onClick={handleSubmission}>Submit</button>
				</div>
			</Paper>
			<FileUploadedDialogue
				onClose={handleCloseDialogue}
				selectedValue={null}
				open={openDialogue}
			></FileUploadedDialogue>
		</div>
	);
}
