import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import "./MenuBar.css";

function MenuBar(props) {
	let addClick = props.addClick;
	return (
		<AppBar position="static">
			<Toolbar variant="dense">
				<IconButton onClick={addClick}>
					<AddIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

export default MenuBar;
