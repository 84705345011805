import { DataStore } from "@aws-amplify/datastore";
import Chart from "react-google-charts";
import { RockDailyStats } from "../models";
import React, { Component, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { render } from "@testing-library/react";
import "./DateSaver.css";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Amplify, { API, Storage } from "aws-amplify";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

const options = {
	title: "Scans uploaded",
};

export function DateSaver() {
	let [saveCounter, setSaveCounter] = useState(0);
	let [value, setValue] = useState(0);
	let [count, setCount] = useState(0);
	let [date, setDate] = useState(new Date(Date.now()));
	let [data, setData] = useState([]);
	let [chartData, setChartData] = useState([]);

	function query2chart(qdata) {
		let ret = [
			[
				{
					type: "date",
					id: "Date",
				},
				{
					type: "number",
					id: "Won/Loss",
				},
			],
		];
		for (let i = 0; i < qdata.length; i++) {
			let date = qdata[i].Date;
			let num = qdata[i].Frequency;
			ret.push([new Date(date), num]);
		}
		setChartData(ret);
	}

	useEffect(() => {
		// declare the async data fetching function
		async function fetchData() {
			let ret = await DataStore.query(RockDailyStats);
			console.log("ayo", ret);
			setData(ret);
			query2chart(ret);

			if (!!filename) {
				const image = await Storage.get(filename);
				setDf(image);
			}

			return "ayo";
		}

		fetchData()
			// make sure to catch any error
			.catch(console.error);
	}, [saveCounter]);

	//   const models = await DataStore.query(RockDailyStats);
	//   console.log(models);
	async function saveStats() {
		console.log("saving....");
		await DataStore.save(
			new RockDailyStats({
				Date: date.toISOString(),
				Frequency: value,
			})
		);
		console.log("saved.");
	}

	const onClick = () => {
		saveStats();
		setCount(count + 1);
		setSaveCounter(saveCounter + 1);
	};

	let [filename, setFilename] = useState("");

	let [df, setDf] = useState();

	async function onChange(e) {
		if (!e.target.files[0]) return;
		const file = e.target.files[0];
		await Storage.put(file.name, file);
		setFilename(file.name);
		console.log("filename: ", file.name);
	}

	return (
		<div className="widget">
			<Paper id="calendarChart">
				<Chart
					className="chart"
					chartType="Calendar"
					width="920px"
					height="200px"
					data={chartData}
					options={options}
				/>
				<div className="form">
					<input
						type="date"
						onChange={(e) => setDate(new Date(e.target.value))}
					></input>
					<br />
					<input
						type="number"
						onChange={(e) => setValue(Number(e.target.value))}
					></input>
					<br />
					<input type="file" onChange={onChange} /> <br />
					<span value={filename} /> <br />
					<button onClick={onClick}>Save that shit</button> <br />
					{df && <img src={df} style={{ width: 400 }} />}
				</div>
			</Paper>
		</div>
	);
}

export default DateSaver;
