import "./App.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Paper from "@mui/material/Paper";
import Box from "./components/Box";
import Dartboard from "./components/Dartboard";
import Model from "./components/Model";
import { Canvas, useFrame } from "@react-three/fiber";
import { Chart } from "react-google-charts";
import { Calendar } from "./components/Calendar";
import { DateSaver } from "./components/DateSaver";
import { AddForm } from "./components/AddForm";
import MenuBar from "./components/MenuBar";
import Gallery from "./components/Gallery";
import React, { Component, useState, useEffect } from "react";
const debug = 1;

function App() {
	let [screen, setScreen] = useState(debug ? "gallery" : "dashboard");
	let [showMenu, setShowMenu] = useState(true);

	const closeAddForm = () => {
		setScreen("dashboard");
	};
	const returnHome = () => {
		console.log(screen);
		setScreen("add");
	};
	return (
		<div className="App">
			{/* <Canvas>
    <ambientLight />
    <pointLight position={[10, 10, 10]} />
    <Box position={[-1.2, 0, 0]} />
    <Box position={[1.2, 0, 0]} />
    <Dartboard/>
    <Model url="texturedMesh.obj"/>
  </Canvas> */}

			{/*	MENU	*/}
			{showMenu && <MenuBar addClick={returnHome}></MenuBar>}

			{/*	VIEWS	*/}
			{screen == "add" && <AddForm closeForm={closeAddForm} />}
			{screen == "dashboard" && <DateSaver />}
			{screen == "gallery" && <Gallery />}
		</div>
	);
}

export default withAuthenticator(App);
