// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const FileType = {
  "HEIC": "HEIC",
  "JPG": "JPG",
  "UNKNOWN": "UNKNOWN"
};

const FolderType = {
  "ZIP": "ZIP",
  "TAR": "TAR",
  "UNKNOWN": "UNKNOWN"
};

const { POOPOO, Moment, RockDailyStats } = initSchema(schema);

export {
  POOPOO,
  Moment,
  RockDailyStats,
  FileType,
  FolderType
};