import "./Gallery.css";

import React, { useState } from "react";
import Paper from "@mui/material/Paper";

export default function Gallery() {
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [numberOfFiles, setNumberOfFiles] = useState(0);
	const [files, setFiles] = useState([]);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
		setNumberOfFiles(numberOfFiles + 1);
		const copyFiles = [...files, event.target.files[0]];
		setFiles(copyFiles);
	};

	const handleSubmission = () => {};

	return (
		<div className="widget">
			<Paper id="calendarChart">asdfasf</Paper>
		</div>
	);
}
