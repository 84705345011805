import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadPage from "./FileUploadPage";
import FileDownloadPage from "./FileDownloadPage";
import "./AddForm.css";

export function AddForm(props) {
	let closeForm = props.closeForm;
	return (
		<div>
			<IconButton id="returnHome" onClick={closeForm}>
				<CloseIcon />
			</IconButton>
			<FileUploadPage />
			{/*
				<FileDownloadPage />
			asdfasfas
			*/}
		</div>
	);
}
